import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import grocery from '../Images/001-groceries.png';
import cosmetics from '../Images/002-cosmetics.png';
import fastFood from '../Images/003-fast-food.png';
import vegetables from '../Images/004-vegetable.png';
import hotels from '../Images/005-hotel.png';
import fish from '../Images/006-fish.png';
import egg from '../Images/007-chicken.png';
import meat from '../Images/008-meat.png';
import fruits from '../Images/009-fruits.png';
import dairy from '../Images/010-milk.png';
import juice from '../Images/011-drink.png';
import medical from '../Images/012-syringe.png';
import rickshaw from '../Images/013-rickshaw.png';
import tour from '../Images/014-travel-and-tourism.png';
import garment from '../Images/015-clothing.png';
import '../CSS/Global.css';
import TimeCountDown from './Coundown';
import User from '../Images/user.jpg'
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const Wallet = () => {
    const [showFilter, setShowFilter] = useState(false);
    const [visibleStatements, setVisibleStatements] = useState(20);
    const [filteredState, setFilteredState] = useState([]);
    const [state, setState] = useState([]);
    const [userwallet, setUserWallet] = useState({});
    const [filterCriteria, setFilterCriteria] = useState({ startDate: '', endDate: '', amount: '' });
    const statementRef = useRef(null);
    const navigate = useNavigate();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const [categories, setCategories] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showCountdown, setShowCountdown] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [tier, setTier] = useState("Basic");
    const [enddate, setEndDate] = useState()
    const [load, setLoad] = useState(true)
    const [isPaid, setIsPaid] = useState(false);
    const [isPlus, setIsPlus] = useState(true);
    const [isTabOpen, setIsTabOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const toast = useToast();
    const location = useLocation();
    const [walletprice, setWalletPrice] = useState()
    // const categories = [
    //     { id: 1, name: 'Grocery', image: grocery, route: '/grocery' },
    //     { id: 2, name: 'Cosmetics', image: cosmetics, route: '/cosmetics' },
    //     { id: 3, name: 'Fast Food', image: fastFood, route: '/fast-food' },
    //     { id: 4, name: 'Vegetables', image: vegetables, route: '/vegetables' },
    //     { id: 5, name: 'Hotels', image: hotels, route: '/hotels' },
    //     { id: 6, name: 'Fish', image: fish, route: '/fish' },
    //     { id: 7, name: 'Egg/Chicken', image: egg, route: '/egg-chicken' },
    //     { id: 8, name: 'Meat', image: meat, route: '/meat' },
    //     { id: 9, name: 'Fruits', image: fruits, route: '/fruits' },
    //     { id: 10, name: 'Dairy Products', image: dairy, route: '/dairy-products' },
    //     { id: 11, name: 'Juice', image: juice, route: '/juice' },
    //     { id: 12, name: 'Medicals', image: medical, route: '/medicals' },
    //     { id: 13, name: 'Rickshaw/Taxi', image: rickshaw, route: '/rickshaw-taxi' },
    //     { id: 14, name: 'Tours & Travels', image: tour, route: '/tours-travels' },
    //     { id: 15, name: 'Readymade & Garments', image: garment, route: '/readymade-garments' },
    // ];

    const handleShowQR = () => {
        navigate('/showqr')
    };
    const handleMyInfo = () => navigate('/user-profile');
    const scrollToStatement = () => statementRef.current?.scrollIntoView({ behavior: 'smooth' });


    const toggleTab = () => {
        setIsTabOpen(!isTabOpen);
        setIsPlus(!isPlus); // Toggle plus/cross state
    };

    const fetchData = () => {

        fetch(`https://100billionoms.com/userBuy/${login.permanent_ID}`, {

        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans.vendors);
                setFilteredState(ans.vendors.reverse());
            })
            .catch((err) => console.log(err));
    };

    const fetchData1 = () => {
        fetch(`https://100billionoms.com/userBuy/current/${login.permanent_ID}`)
            .then(res => res.json())
            .then(data => {
                setState(data.reverse());
            })
            .catch(err => console.log(err));
    };

    const fetchDataWallet = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/register/singleuser/${login.permanent_ID}`);
            const data = await response.json();
            console.log(data);
            setUserWallet(data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCategoryData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/category/getcategory`)
            const res = await response.json();
            console.log(res);
            const activeImages = res.filter(image => image.status === 'Active')
                .sort((a, b) => a.position - b.position);
            setCategories(activeImages);
        } catch (error) {
            console.log(error)
        }
    }

    const fetchWalletData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/wallet-registration`);
            const res = await response.json();
            setWalletPrice(res.data[0].walletsale_price);
            // console.log(res.data[0].walletsale_price);
        } catch (error) {
            console.log(error);
        }
    };

    const decrypt = (encryptedData, iv) => {
        const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

        // Convert key, IV, and encrypted data to appropriate CryptoJS formats
        const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
        const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

        // Decrypt the data using AES with CBC mode
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Return the decrypted UTF-8 string
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    const fetchWalletUserSingly = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/wallet-reg-fee/users/${login.permanent_ID}`);
            const res = await response.json();
            const decryptedData = decrypt(res.data.data, res.data.iv);
            if (decryptedData === "[]") {
                onOpen(); // Open modal if decrypted data is empty
            } else {
                const walletData = JSON.parse(decryptedData);
                checkValidity(walletData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handlepayfirst = () => {
        if (login) {
            toast({
                description: "Pay Registeration Fee First",
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
        toast({
            description: "Login First",
            status: 'warning',
            duration: 5000,
            position: 'top-center',
            isClosable: true,
        });
    }

    const checkValidity = (walletData) => {
        const currentDate = new Date();
        let validSubscription = false;

        for (const data of walletData) {
            const startDate = new Date(data.startDate);
            const endDate = new Date(data.endDate);

            if (currentDate >= startDate && currentDate <= endDate && data.status !== "Failed") {
                validSubscription = true;
                setEndDate(endDate.toISOString());
                const daysRemaining = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                setTimeRemaining(daysRemaining);

                // Assign tiers based on remaining days
                if (daysRemaining > 21) {
                    setTier("Premium");
                } else if (daysRemaining > 7) {
                    setTier("Standard");
                } else {
                    setTier("Basic");
                    setShowCountdown(true);
                }

                break;
            }
        }

        if (!validSubscription) {
            onOpen(); // Open modal if no valid subscription
        }
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };


    const handlePayment = async () => {
        setLoad(false);
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let paymentDetails = {
                user_id: login.permanent_ID,
                user_phone: login.name,
                transaction_id: orderId,
                amount: walletprice * 100,
                validity: 28,
                currency: "INR",
                payment_capture: 1,
                payment_mode: "online",
            };

            // Create order options
            let options = {
                key: "rzp_live_Xe3FsoiFgJrvDM",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "BigBull Paisewala",
                description: "Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'Paid';
                        let result = await axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            //   checkPaymentStatus();
                            // handleSubmit(result.data.payments)
                            navigate("/vendor-wallet")
                            setLoad(true);
                            fetchWalletUserSingly();
                            // setLoad(false);
                            // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            setLoad(true);
                            // setLoad(false);
                            //   window.location.href = "/error-page"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'Failed';
                        const payment = "Failed"
                        await axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails);
                        // handleSubmit(payment)
                        navigate("/vendor-wallet")
                        setLoad(true);
                        // window.location.href = "/error-page"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "bigbullbusinesssolutionspvtltd@gmail.com",
                    contact: "7757855388"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#1f5215"
                },
                modal: {
                    ondismiss: function () {
                        setLoad(true);
                    },
                },
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                paymentDetails.status = 'Failed';
                const payment = "Failed"
                axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails)
                    .then(() => {
                        navigate("/vendor-wallet") // Redirect to an error page

                        setLoad(true);

                    }).catch(error => {
                        navigate("/vendor-wallet") // Redirect to an error page
                        // setShowModal(false); // Close the modal on success
                        // setLoad(false)
                        setLoad(true);
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        fetchCategoryData();
        fetchWalletData();
    }, []);

    useEffect(() => {
        fetchData1();
        fetchDataWallet();
        fetchWalletUserSingly();
    }, []);


    const clearFilter = () => {
        setFilterCriteria({ startDate: '', endDate: '', amount: '' })
        fetchData();
    }
    // Toggle filter visibility
    const toggleFilter = () => setShowFilter(!showFilter);

    // Apply filters based on date and amount
    // Apply filters based on date range and max amount
    // Define totalAmount based on whether filters are applied
    const calculateTotal = (data) => data.reduce((acc, curr) => {
        let currAmount = typeof curr.amount === 'string'
            ? parseFloat(curr.amount.replace(/[^0-9.-]+/g, ''))
            : curr.amount;
        return acc + (currAmount || 0);
    }, 0);

    const totalAmount = (
        filterCriteria.startDate || filterCriteria.endDate || filterCriteria.amount
    ) ? calculateTotal(filteredState) : calculateTotal(state);

    // Calculate `totalAmount` based on the presence of filters
    const applyFilter = () => {
        const { startDate, endDate, amount } = filterCriteria;
        const query = new URLSearchParams({ startDate, endDate, amount }).toString();

        fetch(`https://100billionoms.com/userBuy/${login.permanent_ID}?${query}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans)
                setFilteredState(ans.vendors);
            })
            .catch((err) => console.log(err));
    };



    // Handle changes to filter input fields
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterCriteria(prev => ({ ...prev, [name]: value }));
    };



    console.log(filterCriteria)
    useEffect(() => {
        calculateTotal(state);
    }, [state, filteredState]);

    const handleClick = (subcategory, category) => {
        // console.log(category,)
        if (subcategory.length > 0) {
            navigate(`/sub/${category}`)
        } else {
            navigate(`${category}`)
        }
    }
    // View more statements (show 20 more each time)
    const handleViewMore = () => setVisibleStatements(prev => prev + 20);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Text textAlign={"center"} mt={"30px"}>To Activate Your Wallet you need to pay ₹{walletprice}</Text>
                        <Box mt={"10px"}>
                            <Flex textAlign={"center"} justifyContent={"center"}>

                                {
                                    load ? <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }} onClick={handlePayment} >Pay ₹{walletprice}</Button> :
                                        <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }} ><Spinner /></Button>
                                }
                            </Flex>
                        </Box>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>

                </ModalContent>
            </Modal>
            <div className="wallet-countdown">
                <div className="wallet-countdown-list1">
                    <p className='blinking-text'>{timeRemaining} days left now!</p>
                </div>
                <div className="wallet-countdown-list">
                    <TimeCountDown timeRemaining={timeRemaining} enddate={enddate} />
                </div>
                {timeRemaining <= 7 &&
                    < div className="wallet-countdown-list">
                        <button className='wallet-pay-now'>Renew</button>
                    </div>
                }
            </div >
            <div className="wallet-card">
                <div className="wallet-card-header">
                    <div className="wallet-card-profile">
                        <div className="img">
                            <img src={User} alt="" />
                        </div>
                        <div className="wallet-card-text">
                            <p className='wallet-name'>{userwallet.name}</p>
                            <p className='wallet-permanent-id'>{userwallet.permanent_ID}</p>
                        </div>
                    </div>
                    <div className="wallet-scan-btn">
                        <button className="scan-btn"><i class="fa-solid fa-qrcode" onClick={handleShowQR}></i></button>
                    </div>
                </div>
                <div className="wallet-amount-card">
                    <div className="wallet-total-amt">
                        <p>This month Purchase</p>
                        <h3>₹ {totalAmount}</h3>
                    </div>
                    <div className="wallet-features">
                        <div className="wallet-list">
                            {/* <h3>₹ 1,00,00,000</h3> */}
                            <p style={{ cursor: "pointer" }} className='user-wallet-p' onClick={scrollToStatement}>Statement</p>
                        </div>
                        <div className='vertical-line'></div>
                        <div className="wallet-list">
                            {/* <h3>₹ 1,00,00,000</h3> */}
                            <p className='user-wallet-p' onClick={handleMyInfo}>My info</p>
                        </div>
                        {/* <div className='vertical-line'></div>
                        <div className="wallet-list">
                            <h3 className='qr-code'><i class="fa-solid fa-qrcode"></i></h3>
                            <p>Show QR</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='wallet-statement-category'>
                <div className='wallet-statement' ref={statementRef}>
                    <div className='wallet-statement-title'>
                        <h2>Statement</h2>
                        <p onClick={toggleFilter} className='filter-icon'><i className="fa-solid fa-filter"></i></p>
                    </div>

                    {showFilter && (
                        <div className='filter-form'>
                            <div className='filter-form-flex'>
                                <div className='filter-date'>
                                    <label>Start Date:</label>
                                    <input type='date' name='startDate' value={filterCriteria.startDate} onChange={handleFilterChange} />
                                </div>
                                <div className='filter-date'>
                                    <label>End Date:</label>
                                    <input type='date' name='endDate' value={filterCriteria.endDate} onChange={handleFilterChange} />
                                </div>
                                <div className='filter-amount'>
                                    <label>Amount:</label>
                                    <input type='number' name='amount' value={filterCriteria.amount} placeholder='Enter amount' onChange={handleFilterChange} />
                                </div>
                            </div>
                            <div className='filterapplydivchange'>
                                <button className='filter-button' onClick={applyFilter}>Apply Filter</button>
                                <button className='filterclear' onClick={clearFilter}>Clear Filter</button>
                            </div>

                        </div>
                    )}
                    {
                        filteredState.length > 0 ?
                            <div className='wallet-statement-table'>
                                {filteredState.slice(0, visibleStatements).map((statement, index) => (
                                    <div key={index} className='date-amount'>
                                        <div className='wallet-date'>
                                            <p>{new Date(statement.operationDate).toISOString().split('T')[0]}</p>
                                        </div>
                                        < div className='wallet-amount'>
                                            <p className='amount'>{statement.amount}</p>
                                            <p className='vendor-name'>{statement.vendor_name} - {statement.vendor_permanent_Id}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> : <Text mt={"10px"} textAlign={"center"}>No history till now</Text>
                    }

                    {visibleStatements < filteredState.length && (
                        <div className='view-more'>
                            <button className='view-more-btn' onClick={handleViewMore}>View More</button>
                        </div>
                    )}
                </div>

                <div className='wallet-category'>
                    <div className='wallet-category-title'><h2>Category</h2></div>
                    <div className="wallet-category-grid">
                        {categories.map((category) => (
                            <div
                                key={category.id}
                                className="category-item"
                                onClick={() => handleClick(category.associatedsubcategory, category.category_name)}
                            >
                                <div className="category-image">
                                    <img src={`https://100billionoms.com/${category.cat_image}`} alt={category.category_name} />
                                </div>
                                <p className="category-name">{category.category_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <nav className="header__column header__nav">
                <ul>
                    <li>
                        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                            <i className="fas fa-home"></i> <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        {
                            isPaid ? <Link to="/world" className={location.pathname === '/world' ? 'active' : ''}>
                                <i className="fas fa-globe"></i> <span>Big World</span>
                            </Link> : <Link aria-disabled onClick={handlepayfirst} className={location.pathname === '/world' ? 'active' : ''}>
                                <i className="fas fa-globe"></i> <span>Big World</span>
                            </Link>
                        }

                    </li>
                    {
                        login.type === "vendor" ?
                            <li>
                                <div className="header-plus-icon" onClick={toggleTab}>
                                    <i className={`fa-solid ${isPlus ? 'fa-plus' : 'fa-xmark'}`}></i> {/* Conditional icon rendering */}
                                </div>
                            </li> : null
                    }

                    <li>
                        {
                            login.type === "vendor" ? <Link to="/vendor-wallet" className={location.pathname === '/wallet' ? 'active' : ''}>
                                <i className="fas fa-wallet"></i> <span>Wallet</span>
                            </Link> : <Link to="/wallet" className={location.pathname === '/wallet' ? 'active' : ''}>
                                <i className="fas fa-wallet"></i> <span>Wallet</span>
                            </Link>
                        }
                    </li>
                    <li className='header-order'>
                        <Link to="/order" className={location.pathname === '/order' ? 'active' : ''}>
                            <i class="fa-solid fa-bag-shopping"></i> <span>Orders</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Wallet;
