import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useNavigate
import Slider from 'react-slick';
import '../CSS/Global.css';
import trust from "../Images/trust_2x.gif"
import verified from "../Images/verified.gif"
import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import user from '../Images/user.jpg'
import pagevendor from "../Images/trending.png"
import axios from 'axios';
import Header from './Header';
import { useGlobalContext } from './Context/GlobalContext';
import DetailImageMobile from "./DetailImageMobile"
// Modal Component
const PhotoUploadModal = ({ isOpen, onClose, setIsModalOpen, handleFileUpload, uploadedFiles, storeId, detailVendorGet, setUploadedFiles }) => {
    if (!isOpen) return null;
    const handleSubmitAllImages = async () => {
        const formData = new FormData();
        uploadedFiles.forEach((file) => formData.append("formImages", file));
        try {

            const response = await axios.put(
                `https://100billionoms.com/store/add-store-images/${storeId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log(response)
            
                // alert("Images added successfully!");
                setUploadedFiles([])
                setIsModalOpen(false);
                detailVendorGet();

           
        } catch (error) {
            console.error("Error uploading images:", error);
            // alert("Failed to upload images. Please try again.");
        }
    }


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Upload Photos</h2>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileUpload}
                />
                {uploadedFiles.length > 0 && (
                    <div className="uploaded-files">
                        <h3>Uploaded Files:</h3>
                        <ul>
                            {uploadedFiles.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div>
                    <button onClick={handleSubmitAllImages}>Submit</button>
                    <button style={{ marginLeft: "10px" }} onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

const DetailPageVendor = () => {
    const [activeTab, setActiveTab] = useState('product');
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const detailInfoRef = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate
    const [rating, setRating] = useState(0); // To store the user's rating (number of stars)
    const [description, setDescription] = useState(""); // To store the review description
    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal handlers
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [storeSingle, setSinglevendor] = useState({})
    const [permanent_IDuser, setPermanentIdUser] = useState()
    const [product, setProduct] = useState([])
    const [ratingData, setRatingData] = useState([])
    const [storemainname, setStorename] = useState()
    const [averageRating, setAverageRating] = useState();
    const [amount, setAmount] = useState()
    const { id, name } = useParams();
    const toast = useToast()
    const {
        fetchCartData,
        cart,
        totalCartItem
    } = useGlobalContext();
    console.log(id, name)
    console.log(uploadedFiles);


    const detailVendorGet = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/store/getstorelist/${id}/${name}`);
            const res = await response.json();

            // Parsing the fetched data
            const parsedData = {
                ...res,
                additionalEmails: JSON.parse(res.additionalEmails || "[]"),
                additionalLandlines: JSON.parse(res.additionalLandlines || "[]"),
                additionalMobiles: JSON.parse(res.additionalMobiles || "[]"),
                additionalServices: JSON.parse(res.additionalServices || "[]"),
                additionalWhatsapps: JSON.parse(res.additionalWhatsapps || "[]"),
                businessDetails: JSON.parse(res.businessDetails || "{}"),
                businessTiming: JSON.parse(res.businessTiming || "{}"),
                contactDetails: JSON.parse(res.contactDetails || "{}"),
                formImages: JSON.parse(res.formImages || "[]"),
                paymentDetails: JSON.parse(res.paymentDetails || "{}"),
                servicesDetails: JSON.parse(res.servicesDetails || "{}"),
            };

            console.log(parsedData);

            // Setting parsed data in state
            setPermanentIdUser(parsedData.user_id);
            setStorename(parsedData.businessDetails.businessName);
            setSinglevendor(parsedData);

            if (response.ok) {
                FetchProductdata(parsedData.user_id);
                fetchRatingData(parsedData.user_id);
            }
        } catch (error) {
            console.log(error);
        }
    };


    console.log(permanent_IDuser, storemainname)
    // Function to handle star click
    const handleStarClick = (index) => {
        setRating(index + 1); // Set rating to the star clicked
        onOpen(); // Open the Chakra UI modal when a star is clicked
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log({ user_id: login.permanent_ID, user_name: login.name, rating_point: rating, description });
        // Handle the form submission logic here (e.g., send to API)
        try {
            const response = await fetch(`https://100billionoms.com/rating`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ user_id: login.permanent_ID, user_name: login.name, rating_point: rating, description, store_id: permanent_IDuser, store_name: storemainname })

            })
            if (response.ok) {
                let res = await response.json();
                onClose();
                fetchRatingData(res.data);
                setDescription("");
            }
        } catch (error) {
            console.log(error)
        }

    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        const element = document.getElementById(tabName);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const loremImages = [
        'https://picsum.photos/800/600?random=1',
        'https://picsum.photos/800/600?random=2',
        'https://picsum.photos/800/600?random=3',
        'https://picsum.photos/800/600?random=4',
        'https://picsum.photos/800/600?random=5',
        'https://picsum.photos/800/600?random=6',
        'https://picsum.photos/800/600?random=7',
        'https://picsum.photos/800/600?random=8',
    ];

    const reviews = [
        {
            name: "Aditya",
            review: "Excellent service and very clean rooms. The staff is very friendly and helpful.",
            rating: 5,
            image: 'https://picsum.photos/50/50?random=1',
        },
        {
            name: "Riya",
            review: "Had a wonderful stay! The amenities were top-notch.",
            rating: 5,
            image: 'https://picsum.photos/50/50?random=2',
        },
        {
            name: "Rohit",
            review: "Great location and a comfortable environment.",
            rating: 4,
            image: 'https://picsum.photos/50/50?random=3',
        },
        {
            name: "Sneha",
            review: "The breakfast options were fantastic. Would recommend to others!",
            rating: 5,
            image: 'https://picsum.photos/50/50?random=4',
        },
        {
            name: "Karan",
            review: "Value for money with excellent customer service.",
            rating: 4,
            image: 'https://picsum.photos/50/50?random=5',
        },
    ];

    const FetchProductdata = async (user_idmain) => {
        try {
            const response = await fetch(`https://100billionoms.com/product/getproduct/${user_idmain}`)
            const res = await response.json();
            setProduct(res);

        } catch (error) {
            console.log(error)
        }
    }

    const fetchRatingData = async (rating_id) => {
        try {
            const response = await fetch(`https://100billionoms.com/rating/${rating_id}`);
            const res = await response.json();
            console.log(res.data);

            // Calculate the total sum of rating points
            const totalRatingPoints = res.data.reduce((sum, rating) => {
                return sum + parseFloat(rating.rating_point);
            }, 0);

            // Calculate the average rating
            const averageRating = totalRatingPoints / res.data.length;

            console.log("Average Rating:", averageRating);

            console.log(res.data)
            // Store the average rating and rating data
            setRatingData(res.data);  // Store the individual ratings
            setAverageRating(averageRating);  // Store the calculated average

        } catch (error) {
            console.log(error);
        }
    };

    const fetchDataPrice = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/trendingprice`);
            const res = await response.json();

            setAmount(res.data[0].trending_price);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        detailVendorGet();
        fetchDataPrice();
    }, [])
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };


    const handleMaketrending = async () => {
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let paymentDetails = {

                user_id: storeSingle.user_id,
                user_phone: storeSingle.user_name,
                transaction_id: orderId,
                amount: amount * 100,
                currency: "INR",
                payment_capture: 1,
                payment_mode: "online",
            };

            // Create order options
            let options = {
                key: "rzp_live_Xe3FsoiFgJrvDM",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "BigBull Paisewala",
                description: "Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'Paid';
                        let result = await axios.post(`https://100billionoms.com/trending`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            //   checkPaymentStatus();
                            closeModal1();
                            // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            //   window.location.href = "/error-page"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'Failed';
                        await axios.post(`https://100billionoms.com/trending`, paymentDetails);
                        closeModal1();
                        // window.location.href = "/error-page"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "bigbullbusinesssolutionspvtltd@gmail.com",
                    contact: "7757855388"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#1f5215"
                },
                modal: {
                    ondismiss: function () {
                        // Handle Razorpay modal close
                        // setLoad(false);
                        closeModal1();
                    },
                },
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                paymentDetails.status = 'Failed';
                axios.post(`https://100billionoms.com/trending`, paymentDetails)
                    .then(() => {
                        closeModal1();
                    }).catch(error => {
                        navigate("/") // Redirect to an error page
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    }

    const formatTime = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12; // Convert 0 to 12 for midnight and adjust hours above 12
        return `${adjustedHour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            if (scrollPercentage > 30) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // console.log(storeSingle.contactDetails.personName,storeSingle.businessDetails.businessName)

    const handleClickClaim = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/claim-request`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: login.permanent_ID,
                    user_phone: login.name,
                    store_name: storeSingle.businessDetails.businessName,
                    store_details: storeSingle
                })
            })
            // const res = await response.json();
            if (response.ok) {
                const res = await response.json();
                toast({
                    description: res.message,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
            else if (response.status === 400) {
                const res = await response.json();
                toast({
                    description: res.message,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                console.log(res);
            }


        } catch (error) {
            console.log(error)
        }
    }

    const [claimreq, setClaimRequest] = useState("")
    const checkClaimStatus = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/claim-request/matchingclaim/${login.permanent_ID}/${id}/${name}`)
            const res = await response.json();
            console.log(res.single);
            setClaimRequest(res.single.status)

        } catch (error) {
            console.log(error);
        }
    }

    const handleAddCart = async (product) => {
        try {
            const response = await fetch(`https://100billionoms.com/cart`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: login.permanent_ID,
                    user_phone: login.name,
                    store_id: permanent_IDuser,
                    product: product
                })
            })
            const res = await response.json();
            if (response.ok) {
                toast({
                    description: res.message,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                fetchCartData();
            } else if (response.status == 400) {
                toast({
                    description: res.message,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
            console.log(res);
        } catch (error) {
            console.log(error)
        }
    }

    console.log(storeSingle);
    const handleWhatsAppClick = (storeName, mobile) => {
        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = `+91${mobile}`; // Replace with the store's WhatsApp number
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };

    const handleShareClick = (name, number, idN) => {
        const shareData = {
            title: "Check this out!",
            text: `Visit our website for more details! https://100billionoms.com/detail-listing/${idN}/${name}`,
            url: `https://100billionoms.com/detail-listing/${idN}/${name}` // Replace with your website URL
        };

        // Check if the browser supports the navigator.share API
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Successfully shared'))
                .catch((error) => console.error('Error sharing', error));
        } else {
            // Fallback: Copy the link to the clipboard for desktop users
            navigator.clipboard.writeText(shareData.text)
                .then(() => alert('Link copied to clipboard!'))
                .catch((error) => console.error('Could not copy link', error));
        }
    };


    const calculateYearsInBusiness = (businessDate) => {
        const businessDateObject = new Date(businessDate); // Parse the business date
        const currentDate = new Date(); // Get the current date

        const diffInMilliseconds = currentDate - businessDateObject; // Difference in milliseconds
        const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Average milliseconds in a year (accounting for leap years)

        const yearsInBusiness = diffInMilliseconds / millisecondsPerYear; // Convert to years
        return Math.round(yearsInBusiness); // Round to the closest year
    };
    console.log(claimreq)

    useEffect(() => {
        checkClaimStatus();
    }, [])

    return (
        <>
            <Header />
            <div className="detail-image">
                <div className="detail-image-grid">
                    {storeSingle && storeSingle.formImages && storeSingle.formImages.length > 0 ? (
                        <>
                            {/* Render the first image */}
                            <div className="detail-img1" onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}>
                                <img
                                    src={`https://100billionoms.com/${storeSingle.formImages[0]}`}
                                    alt="Image 1"
                                />
                            </div>

                            {/* Render placeholder or empty spaces if fewer than 4 images */}
                            {storeSingle.formImages.length > 1 && (
                                <div className="detail-img2" onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}>
                                    <img
                                        src={`https://100billionoms.com/${storeSingle.formImages[1]}`}
                                        alt="Image 2"
                                    />
                                </div>
                            )}

                            {storeSingle.formImages.length > 2 && (
                                <div className="detail-img3" onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}>
                                    <img
                                        src={`https://100billionoms.com/${storeSingle.formImages[2]}`}
                                        alt="Image 3"
                                    />
                                </div>
                            )}

                            {storeSingle.formImages.length > 3 && (
                                <div className="detail-img4" onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)}>
                                    <img
                                        src={`https://100billionoms.com/${storeSingle.formImages[3]}`}
                                        alt="Image 4"
                                    />
                                </div>
                            )}

                            {/* Only show the "More" overlay if there are more than 4 images */}
                            {storeSingle.formImages.length > 4 && (
                                <div className="detail-img5">
                                    <img
                                        src={`https://100billionoms.com/${storeSingle.formImages[4]}`}
                                        alt="Additional images available"
                                    />
                                    <div className="overlay" onClick={() => navigate(`/gallery/${storeSingle.id}/${storeSingle.businessDetails.businessName}`)} style={{ cursor: 'pointer' }}>
                                        +{storeSingle.formImages.length - 4} More
                                    </div>
                                </div>
                            )}

                        </>
                    ) : (

                        <div className="no-images">
                            <p>No images available</p>
                        </div>
                    )}

                    {/* "Add More" overlay, always visible */}
                    {
                        login.permanent_ID === permanent_IDuser ?
                            <div className="detail-img6">
                                <img
                                    src={storeSingle && storeSingle.formImages && storeSingle.formImages?.length > 5
                                        ? `https://100billionoms.com/${storeSingle.formImages[5]}`
                                        : (storeSingle && storeSingle.formImages?.length > 0
                                            ? `https://100billionoms.com/${storeSingle.formImages[0]}`
                                            : "https://picsum.photos/400/300?random=6"
                                        )
                                    }
                                    alt="Add more images to gallery"
                                />
                                <div className="overlay">
                                    <span style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
                                        Add More Photos
                                    </span>


                                </div>
                            </div> :
                            storeSingle && storeSingle.formImages && storeSingle.formImages?.length > 5 ?
                                <>
                                    {login.permanent_ID === permanent_IDuser ?
                                        <div className="detail-img6">
                                            <img
                                                src={storeSingle && storeSingle.formImages && storeSingle.formImages?.length > 5
                                                    ? `https://100billionoms.com/${storeSingle.formImages[5]}`
                                                    : (storeSingle && storeSingle.formImages?.length > 0
                                                        ? `https://100billionoms.com/${storeSingle.formImages[0]}`
                                                        : "https://picsum.photos/400/300?random=6"
                                                    )
                                                }
                                                alt="Add more images to gallery"
                                            />
                                        </div> : null
                                    }
                                </> :
                                <>
                                    {login.permanent_ID === permanent_IDuser ?
                                        <div className="detail-img6">

                                            <img
                                                src={storeSingle && storeSingle.formImages && storeSingle.formImages?.length > 5
                                                    ? `https://100billionoms.com/${storeSingle.formImages[5]}`
                                                    : (storeSingle && storeSingle.formImages?.length > 0
                                                        ? `https://100billionoms.com/${storeSingle.formImages[0]}`
                                                        : "https://picsum.photos/400/300?random=6"
                                                    )
                                                }
                                                alt="Add more images to gallery"
                                            />
                                            <div className="overlay">
                                                {login.permanent_ID === permanent_IDuser ?
                                                    < span style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
                                                        Add More Photos
                                                    </span> : null
                                                }
                                            </div>
                                        </div> : null
                                    }
                                </>
                    }
                    {
                        login.permanent_ID !== permanent_IDuser && storeSingle.formImages?.length > 5 ?
                            <div className="detail-img6">
                                <img
                                    src={storeSingle && storeSingle.formImages && storeSingle.formImages?.length > 5
                                        ? `https://100billionoms.com/${storeSingle.formImages[5]}`
                                        : (storeSingle && storeSingle.formImages?.length > 0
                                            ? `https://100billionoms.com/${storeSingle.formImages[0]}`
                                            : "https://picsum.photos/400/300?random=6"
                                        )
                                    }
                                    alt="Add more images to gallery"
                                />

                            </div> : null
                    }

                </div>

                
                {storeSingle && storeSingle.formImages && storeSingle.formImages.length > 0 && (
                    <DetailImageMobile detailVendorGet={detailVendorGet} storeSingle={storeSingle} formImages={storeSingle.formImages}  />
                )}
            </div >



            {/* Modal Popup */}
            <div className='detail-popup'>
                <PhotoUploadModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    setIsModalOpen={setIsModalOpen}
                    handleFileUpload={handleFileUpload}
                    uploadedFiles={uploadedFiles}
                    storeId={storeSingle.id}
                    detailVendorGet={detailVendorGet}
                    setUploadedFiles={setUploadedFiles}
                />
            </div>
            <div className='detail-list-info'>
                <h1>{storeSingle.businessDetails && storeSingle.businessDetails.businessName}</h1>
                <div className='detail-list-review'>
                    {
                        ratingData?.length > 0 ? <div className='detail-review-star'>
                            {averageRating} <i className="fa-solid fa-star"></i>
                        </div> : null
                    }
                    <div className='detail-lis t-number'>{ratingData?.length > 0 ? ratingData.length : 0} ratings</div>
                    {
                        storeSingle && storeSingle.trusted === true ? <div className='detail-list-trust1'>
                            <img src={trust} className='whichicopied' />
                        </div> : null
                    }

                    {
                        storeSingle && storeSingle.verified === true ? <div className='detail-list-verified1'>
                            <img src={verified} className='whichicopied' />
                        </div> : null
                    }

                    {
                        storeSingle && storeSingle.claim === false && claimreq === ""
                            ? <div className='detail-list-claimed' onClick={handleClickClaim}>Claim this business</div>
                            : storeSingle.claim === true
                                ? <div className='detail-list-claimed'><i class="fa-solid fa-circle-check" style={{ marginRight: "5px" }}></i>Claimed</div> :
                                claimreq === "Pending" ? <div className='detail-list-claimedpending'>Pending</div>
                                    : null
                    }

                </div>
                <div className='detail-list-location'>

                    <p>
                        <i className="fa-solid fa-location-dot"></i>
                        {storeSingle.businessDetails && [
                            // storeSingle.businessDetails.buildingName,
                            // storeSingle.businessDetails.street,
                            // storeSingle.businessDetails.area,
                            // storeSingle.businessDetails.landmark,
                            storeSingle.businessDetails.city,
                            storeSingle.businessDetails.state,
                            storeSingle.businessDetails.pincode
                        ]
                            .filter(Boolean) // Filters out empty, null, or undefined values
                            .join(', ')}
                        {
                            storeSingle.businessDate === null || "" ?
                                null :
                                <span className='business-year'>
                                    <span className='business-dot'></span> {calculateYearsInBusiness(storeSingle.businessDate)} year in business
                                </span>
                        }

                    </p>
                </div>
                <div className='detail-list-contact'>
                    <div className='detail-list-contact-left'>
                        <Link to={`tel:${storeSingle.contactDetails && storeSingle.contactDetails.mobile}`}>
                            <p className='detail-list-contact-phone' ><i className="fa-solid fa-phone detail-list-phone"></i>{storeSingle.contactDetails && storeSingle.contactDetails.mobile} </p>
                        </Link>
                        {/* <p className='detail-list-contact-enquiry' 
                        
                        ><i className="fa-solid fa-message"></i> Enquire Now</p> */}
                        <p className='detail-list-contact-whatsapp' onClick={() => handleWhatsAppClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp)}><i className="fa-brands fa-whatsapp"></i> WhatsApp</p>

                        {login.type === "vendor" && login.permanent_ID === permanent_IDuser ? <p className='detail-list-contact-phone' onClick={openModal1}><i className="fa-solid fa-plus"></i> Trending</p> : null}
                        <p className='detail-list-contact-share' onClick={() => handleShareClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp, storeSingle.id)}><i className="fa-solid fa-share detail-list-share"></i></p>
                    </div>
                    <Box className="detail-list-contact-right">
                        <Box className="detail-list-tap">Tap to review</Box>
                        <Box className="detail-list-review-button" display="flex" gap="8px">
                            {[...Array(5)].map((_, index) => (
                                <i
                                    key={index}
                                    className={`fa-star ${index < rating ? "fa-solid" : "fa-regular"}`} // Fill stars based on rating
                                    onClick={() => handleStarClick(index)} // Set rating based on star clicked
                                    style={{ cursor: "pointer", color: index < rating ? "#FFD700" : "#C0C0C0" }} // Change color on click
                                />
                            ))}
                        </Box>

                        {/* Chakra UI Modal for review submission */}
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Submit your review</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Write your review here..."
                                        required
                                    />
                                </ModalBody>

                                <ModalFooter>
                                    <Button bg={"#F91919"} color={"white"} _hover={{ background: "#c20707", color: "white" }} mr={3} onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                    <Button variant="ghost" onClick={onClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Box>
                </div>
            </div>
            <div className={`detail-list-info-fixed ${isVisible ? 'visible' : 'hidden'}`} ref={detailInfoRef}>
                <div className='detail-list-left-fixed'>
                    <h1>{storeSingle.businessDetails && storeSingle.businessDetails.businessName}</h1>
                    <div className='detail-list-review'>
                        {
                            ratingData?.length > 0 ? <div className='detail-review-star'>
                                {averageRating} <i className="fa-solid fa-star"></i>
                            </div> : null
                        }
                        <div className='detail-list-number'>{ratingData.length > 0 ? ratingData.length : 0} ratings</div>
                        {storeSingle && storeSingle.trusted === true ? <div className='detail-list-trust'> <img src={trust} className='whichicopied' /></div> : null}
                        {storeSingle && storeSingle.verified === true ? <div className='detail-list-verified'> <img src={verified} className='whichicopied' /></div> : null}
                        {console.log(storeSingle && storeSingle.claim === false && login.permanent_ID !== permanent_IDuser && claimreq === "")}
                        {
                            (storeSingle && storeSingle.claim === false && login.permanent_ID !== permanent_IDuser && claimreq === "") ? <div className='detail-list-claimed'>claim this business</div> : storeSingle.claim === true ? <div className='detail-list-claimed'>claimed</div> : claimreq === "Pending" ? <div className='detail-list-claimed'>Pending</div> : null
                        }
                        {/* <div className='detail-list-claimed'>claimed</div> */}
                    </div>
                </div>
                <div className='detail-list-right-fixed'>
                    <div className='detail-list-contact-left'>
                        <Link to={`tel:${storeSingle.contactDetails && storeSingle.contactDetails.mobile}`}>
                            <p className='detail-list-contact-phone'><i className="fa-solid fa-phone detail-list-phone"></i> {storeSingle.contactDetails && storeSingle.contactDetails.mobile}</p>
                        </Link>
                        {/* <p className='detail-list-contact-enquiry'><i className="fa-solid fa-message"></i> Enquire Now</p> */}
                        <p className='detail-list-contact-whatsapp' onClick={() => handleWhatsAppClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp)}><i className="fa-brands fa-whatsapp"></i> WhatsApp</p>
                        <p className='detail-list-contact-share' onClick={() => handleShareClick(storeSingle.businessDetails.businessName, storeSingle.contactDetails.whatsapp, storeSingle.id)}><i className="fa-solid fa-share detail-list-share"></i></p>
                    </div>
                </div>
            </div>
            <Modal blockScrollOnMount={false} isOpen={isModal1Open} onClose={closeModal1}
                size={{ base: 'full', md: 'lg', lg: '3xl' }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Become Trending</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}
                        <Box>
                            <Text marginTop={"20px"} fontSize='lg' textAlign={"center"}>After becoming a trending vendor, your details will be displayed in the 'Trending Vendors' section for 30 days, as shown in the image below.</Text>
                            <Box border={"2px solid #F91919"} marginTop={"20px"} borderRadius={"10px"}>
                                <Image borderRadius={"10px"} src={pagevendor} />
                            </Box>
                            <Text marginTop={"20px"} fontSize='lg' textAlign={"center"}>Click on the below button to become a trending vendor.</Text>
                            <Button display={"block"} margin={"auto"} cursor={"pointer"} marginTop={"20px"} _hover={{ background: "#F91919", color: "#fff" }} background={"#F91919"} color={"#fff"} onClick={() => handleMaketrending(storeSingle.user_id, storeSingle.user_name)}>Pay ₹{amount}</Button>
                        </Box>
                    </ModalBody>

                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            <div className="tab-navigation">
                <ul>
                    <li onClick={() => handleTabClick('product')} className={activeTab === 'product' ? 'active' : ''}>Product</li>
                    <li onClick={() => handleTabClick('overview')} className={activeTab === 'overview' ? 'active' : ''}>Overview</li>
                    {storeSingle.additionalServices && storeSingle.additionalServices.length > 0 ? <li onClick={() => handleTabClick('services')} className={activeTab === 'services' ? 'active' : ''}>Services</li> : null}
                    <li onClick={() => handleTabClick('quick-info')} className={activeTab === 'quick-info' ? 'active' : ''}>Quick Info</li>
                    {/* <li onClick={() => handleTabClick('photos')} className={activeTab === 'photos' ? 'active' : ''}>Photos</li> */}
                    <li onClick={() => handleTabClick('review')} className={activeTab === 'review' ? 'active' : ''}>Review</li>
                </ul>
            </div>

            <div className="tab-content">
                <div id="product" className="product-section">
                    <div className="detail-product">
                        <h2>Product</h2>
                        {
                            login.permanent_ID === permanent_IDuser ? <Link to={"/product"}> <button className="product-add-more" >
                                + Add More Products
                            </button>
                            </Link> : null
                        }

                    </div>
                    {console.log(product)}
                    {
                        product.length > 0 ? <>
                            {
                                product.map((ele, idx) => {
                                    return <div className='product-card'>
                                        <div className='product-content'>
                                            <div className='product-image'>
                                                <img src={`https://100billionoms.com/${ele.product_image}`} alt="Stylish Sneakers" />
                                            </div>
                                            <div className='product-details'>
                                                <h3>{ele.product_name}</h3>
                                                <p className='product-description'>{ele.description}</p>
                                                <p className='product-description'>Quantity: {ele.quantity}{" "}{ele.unit}</p>
                                                <p className='product-price'>Price: ₹{ele.sell_price}</p>
                                                {/* <p className='product-price'></p> */}
                                            </div>
                                        </div>
                                        <div className='product-action'>
                                            <button className='addtoproductedit' onClick={() => navigate(`/edit-product/${ele.id}`)}><i class="fa-solid fa-pen fa-sm"></i></button>
                                            <button className='add-to-cart-btn' onClick={() => handleAddCart(ele)}>Add +</button>
                                        </div>
                                    </div>

                                })
                            }

                        </> : <p className='colortonoproduct'>No products available</p>
                    }


                </div>
                <div id="overview" className="overview-section">
                    <h2>Overview</h2>
                    <p>
                        {storeSingle.businessDetails && storeSingle.businessDetails.description}
                    </p>
                </div>
                {
                    storeSingle.additionalServices && storeSingle.additionalServices.length > 0 && Array.isArray(storeSingle.additionalServices) ?
                        <div id="services">
                            <h2>Services</h2>
                            <ul className="services-list">
                                <li>
                                    <i className="fa-solid fa-circle "></i> <strong>{storeSingle.servicesDetails && storeSingle.servicesDetails.service}</strong>
                                </li>
                                {
                                    storeSingle.additionalServices && storeSingle.additionalServices.length > 0 ?
                                        <>
                                            {
                                                storeSingle.additionalServices.map((ele, idx) => {
                                                    return <li>
                                                        <i className="fa-solid fa-circle"></i> <strong>{ele}</strong>
                                                    </li>
                                                })
                                            }
                                        </> : null
                                }
                            </ul>
                        </div> : null
                }

                <div id="quick-info">
                    <h2>Quick Info</h2>
                    <ul className="quick-info-list">
                        <li className="business-hours">
                            <i className="fa-solid fa-clock"></i>
                            <strong>Business Hours:</strong>
                            <div className="time-slots-container">
                                {storeSingle.businessTiming?.timeSlots && storeSingle.businessTiming.timeSlots.map((slot, index) => (
                                    <div key={index} className="time-slot">
                                        <strong>{slot.day}:</strong> {formatTime(slot.openTime)} - {formatTime(slot.closeTime)}
                                    </div>
                                ))}
                            </div>
                        </li>


                        <li>
                            <i className="fa-solid fa-phone"></i> <strong>Contact:</strong> {storeSingle.contactDetails && storeSingle.contactDetails.mobile}
                        </li>
                        <li>
                            <i className="fa-solid fa-location-dot"></i> <strong>Address:</strong> {storeSingle.businessDetails && [
                                storeSingle.businessDetails.buildingName,
                                storeSingle.businessDetails.street,
                                storeSingle.businessDetails.area,
                                storeSingle.businessDetails.landmark,
                                storeSingle.businessDetails.city,
                                storeSingle.businessDetails.state,
                                storeSingle.businessDetails.pincode
                            ]
                                .filter(Boolean) // Filters out empty, null, or undefined values
                                .join(', ')}
                        </li>
                    </ul>
                </div>

                {/* <div id="photos" className='detail-list-tab-img'>
                    <h2>Photos</h2>
                    <Slider {...settings}>
                        {loremImages.map((imageSrc, index) => (
                            <div key={index} className="more-photo-img">
                                <img src={imageSrc} alt={`Lorem Image ${index + 1}`} />
                            </div>
                        ))}
                    </Slider>
                </div> */}
                <div id="review">
                    <h2>Reviews</h2>
                    {
                        ratingData.length > 0 ?
                            <>
                                {ratingData.map((review, index) => (
                                    <div className='detail-tab-review' key={index}>
                                        <div className='tab-review-info'>
                                            <img src={user} alt={review.name} style={{ borderRadius: '50%', width: '50px', height: '50px' }} />
                                            <p><strong>{review.user_name}</strong></p>
                                        </div>
                                        <div className="review-stars">
                                            {Array.from({ length: review.rating_point }, (_, i) => (
                                                <i key={i} className="fa fa-star"></i>
                                            ))}
                                            {Array.from({ length: 5 - review.rating_point }, (_, i) => (
                                                <i key={i + review.rating_point} className="fa fa-star-o"></i>
                                            ))}
                                        </div>
                                        <div className='review-tab-para'>
                                            <p>{review.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </> :
                            <h4>No reviews yet</h4>
                    }
                </div>
            </div>
        </>
    );
};

export default DetailPageVendor;
