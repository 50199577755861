import React, { useState, useEffect } from 'react';
import { Box, Button, Image, Text, Flex } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Slider = () => {
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch('https://100billionoms.com/ads/all/Adsall');
        const adsData = await response.json();

        // Filter ads based on specified conditions
        const today = new Date().toISOString();
        const validAds = adsData.filter(ad =>
          ad.status === 'Active' &&
          ad.end >= today &&
          ad.place_go === 'BigBull-World-Bottom'
        );

        setAds(adsData);
        setFilteredAds(validAds);
      } catch (error) {
        console.log('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  // Navigation functions
  const nextSlide = () => {
    if (currentIndex < filteredAds.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Hide if no ads meet criteria
  if (filteredAds.length === 0) return null;

  const { title, banner, description } = filteredAds[currentIndex];

  return (
    <Box w="100%" textAlign="center" pb={2} pt={2} position="relative">
      {/* Slide Image */}
      <Image
        src={`https://100billionoms.com/${banner}`}
        alt={title}
        borderRadius="md"
        objectFit="cover"
        w="100%"
      />

      {/* Title and Description */}
      {/* Navigation Buttons */}
      {filteredAds.length > 1 && ( 
        <Flex justifyContent="space-between" position="absolute" top="50%" left="0" right="0" transform="translateY(-50%)">
          {/* Left Button */}
          <Button
            onClick={prevSlide}
            isDisabled={currentIndex === 0}
            variant="outline"
            colorScheme="red"
            borderRadius="50%"           // Makes the button circular
            width="40px"                 // Width and height set to equal values for a perfect circle
            height="40px"
            opacity={currentIndex === 0 ? 0.5 : 1}
          >
            <ChevronLeftIcon boxSize={6} />  {/* Adjust icon size as needed */}
          </Button>

          {/* Right Button */}
          <Button
            onClick={nextSlide}
            isDisabled={currentIndex === filteredAds.length - 1}
            variant="outline"
            colorScheme="red"
            borderRadius="50%"           // Makes the button circular
            width="40px"                 // Width and height set to equal values for a perfect circle
            height="40px"
            opacity={currentIndex === filteredAds.length - 1 ? 0.5 : 1}
          >
            <ChevronRightIcon boxSize={6} />  {/* Adjust icon size as needed */}
          </Button>
        </Flex>
      )}

    </Box>
  );
};

export default Slider;
