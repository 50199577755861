import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import Header from '../Components/Header';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useDisclosure } from '@chakra-ui/react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
} from '@chakra-ui/react';


const NewAds = () => {
    const [filter, setFilter] = useState('all-ads');
    let navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [currentPage, setCurrentPage] = useState(1);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let [order, setOrder] = useState([]);
    const [imagesee, setImagesee] = useState();

    const decrypt = (encryptedData, iv) => {
        const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

        // Convert key, IV, and encrypted data to appropriate CryptoJS formats
        const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
        const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

        // Decrypt the data using AES with CBC mode
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Return the decrypted UTF-8 string
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    let fetchOrderData = async () => {
        try {
            let response = await fetch(`https://100billionoms.com/ads/all/${login.permanent_ID}`)
            let res = await response.json();
            console.log(res);
            setOrder(res.reverse());
            // const decryptedData = decrypt(res.data.data, res.data.iv);
            // console.log(decryptedData)
            // if (decryptedData) {
            //     const parsedData = JSON.parse(decryptedData);
            //     console.log(parsedData)
            //     setOrder(parsedData.reverse());

            //     // Check if any object's status is 'paid'

            // } else {
            //     console.error("Decrypted data is empty or invalid");
            // }

        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = async (Change, id) => {
        try {
            let response = await fetch(`https://100billionoms.com/cart-orders/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ status: Change })
            })
            if (response.ok) {
                let res = await response.json()
                console.log(res);
                setOrder(prevData => prevData.map(order => {
                    if (order.transaction_id === id) {
                        return { ...order, status: Change }; // Update the orderStatus of the specific order
                    }
                    return order;
                }));

            }
        } catch (error) {
            console.log(error)
        }
    }

    const userPerPage = 8;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = order.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(order.length / userPerPage);

    let logout = () => {

        localStorage.removeItem("login");
        window.location.reload();
    }

    const handleOpenModal = (ele) => {
        onOpen();
        setImagesee(ele.banner);
    }

    useEffect(() => {
        fetchOrderData();
    }, [])

    // Individual ad data
    // const Ads1 = {
    //     id: '12345678',
    //     date: '28 Nov 2024',
    //     status: 'active',
    //     plan: 'Premium',
    //     total: 1810,
    //     orderedBy: 'Thakur Singh',
    // };

    // const Ads2 = {
    //     id: '12345681',
    //     date: '28 Nov 2024',
    //     status: 'pending',
    //     plan: 'Basic',
    //     total: 1810,
    //     orderedBy: 'Thakur Singh',
    // };

    // Filter logic for individual ads
    const renderAd = (ad) => {
        const plan = JSON.parse(ad.plan || "{}");
        // if (filter === 'all-ads' || ad.status === filter) {
            return (
                <div className="new-order-card" key={ad.id}>
                    <div className="order-number">
                        <p className='order-id'>Order #{ad.id}</p>
                        <p className='order-date'>On {new Date(ad.createdAt).toLocaleDateString()}</p>
                    </div>
                    <div className="order-status">
                        <p className={ad.status}>
                            Order {ad.status.charAt(0).toUpperCase() + ad.status.slice(1)}
                        </p>
                    </div>
                    <div className="order-list">
                        <p className="plan">Plan: {plan.plan_name || "N/A"}</p>
                    </div>
                    <div className="amount-tract-btn">
                        <div className="order-amount">
                            <p>Total</p>
                            <h3>₹ {plan.price}</h3>
                        </div>
                        <div className="order-track-btn">
                            {/* <Link to='/invoice'>
                                <button className="track-btn">Invoice</button>
                            </Link> */}
                        </div>
                    </div>
                    <div className="ordered-by">
                        <p>Ordered by: <span className="ordered-by-name">{ad.user_name}</span></p>
                    </div>
                </div>
            );
        // }
        return null;
    };

    return (
        <>
        <AlertDialog
                motionPreset="slideInBottom"
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Invoice</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Download Invoice Coming Soon!
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
            <Header />
            <div className="new-orders">
                <div className="new-order-container">
                <div className="order-page-heading">
                    <h2>Ads history</h2>
                </div>
                    {/* <div className="order-dropdown">
                        <select
                            name="order-select"
                            id="order-select"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <option value="">All Ads</option>
                            <option value="Pending">Active</option>
                            <option value="Pending">Pending</option>
                        </select>
                    </div> */}
                    
                        {order.map(renderAd)}
                    
                    {/* {renderAd(Ads1)} */}
                    {/* {renderAd(Ads2)} */}
                </div>
            </div>
        </>
    );
};

export default NewAds;
