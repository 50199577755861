import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import Header from '../Components/Header';
import CryptoJS from 'crypto-js'; // For decryption
import orderimg from "../Images/noorder.jpg"

const NewOrder = () => {
    const [filter, setFilter] = useState('all-order');
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalOrderId, setModalOrderId] = useState(null);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);

    // Orders data
    // const orders = [
    //     {
    //         id: '12345678',
    //         date: '28 Nov 2024',
    //         status: 'active',
    //         items: ['Item 1', 'Item 2'],
    //         total: 1810,
    //         orderedBy: 'Thakur Singh',
    //     },
    //     {
    //         id: '12345679',
    //         date: '28 Nov 2024',
    //         status: 'delivered',
    //         items: ['Item 1', 'Item 2'],
    //         total: 1810,
    //         orderedBy: 'Thakur Singh',
    //     },
    //     {
    //         id: '12345680',
    //         date: '28 Nov 2024',
    //         status: 'returned',
    //         items: ['Item 1', 'Item 2'],
    //         total: 1810,
    //         orderedBy: 'Thakur Singh',
    //     },
    //     {
    //         id: '12345681',
    //         date: '28 Nov 2024',
    //         status: 'cancelled',
    //         items: ['Item 1', 'Item 2'],
    //         total: 1810,
    //         orderedBy: 'Thakur Singh',
    //     },
    // ];


    let navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let [order, setOrder] = useState([]);

    const decrypt = (encryptedData, iv) => {
        const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

        // Convert key, IV, and encrypted data to appropriate CryptoJS formats
        const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
        const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

        // Decrypt the data using AES with CBC mode
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Return the decrypted UTF-8 string
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    let fetchOrderData = async () => {
        try {
            let response = await fetch(`https://100billionoms.com/cart-orders/store/${login.permanent_ID}`)
            let res = await response.json();
            console.log(res.data);
            const decryptedData = decrypt(res.data.data, res.data.iv);
            console.log(decryptedData)
            if (decryptedData) {
                const parsedData = JSON.parse(decryptedData);
                const parsedOrders = parsedData.map((order) => ({
                    ...order,
                    cart_product: JSON.parse(order.cart_product),
                    user_address: JSON.parse(order.user_address),
                    vendor_address: JSON.parse(order.vendor_address),
                }));
                setOrder(parsedOrders.reverse());

                // Check if any object's status is 'paid'

            } else {
                console.error("Decrypted data is empty or invalid");
            }

        } catch (error) {
            console.log(error);
        }
    }


    const handleChange = async (Change) => {
        try {
            let response = await fetch(`https://100billionoms.com/cart-orders/${modalOrderId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ status: Change })
            })
            if (response.ok) {
                let res = await response.json()
                console.log(res);
                setOrder(prevData => prevData.map(order => {
                    if (order.transaction_id === modalOrderId) {
                        return { ...order, status: Change }; // Update the orderStatus of the specific order
                    }
                    return order;
                }));
                setCancelModalOpen(false)

            }
        } catch (error) {
            console.log(error)
        }
    }

    const userPerPage = 5;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = order.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(order.length / userPerPage);
    useEffect(() => {
        fetchOrderData();
    }, [])
    console.log(order);
    // Filter logic
    const renderOrder = (order) => {
        if (filter === 'all-order' || order.status === filter) {
            return (
                <div className="new-order-card" key={order.id}>
                    <div className="order-number">
                        <p className='order-id'>Order #{order.transaction_id}</p>
                        <p className='order-date'>On {new Date(order.createdAt).toLocaleDateString()}</p>
                    </div>
                    <div className="order-status">
                        <p className={order.status.toLowerCase()}>
                            Order {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </p>
                    </div>
                    <div className="order-list">
                        <h4>Items:</h4>
                        {order.cart_product.map((item, i) => (
                            <div key={i} className="item-details">
                                <p><strong>Product:</strong> {item.product.product_name}</p>
                                <p><strong>Quantity:</strong> {item.quantity}</p>
                                <p><strong>Price:</strong> ₹{item.product.sell_price.toFixed(2)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="amount-tract-btn">
                        <div className="order-amount">
                            <p>Total Amount</p>
                            <h3>₹{(order.amount_p || 0).toFixed(2)}</h3>
                        </div>
                        <div className="order-track-btn">
                            {order.status === "Pending" && (
                                <button
                                    className="cancel-order"
                                    onClick={() => {
                                        setCancelModalOpen(true);
                                        setModalOrderId(order.transaction_id);
                                    }}
                                >
                                    Cancel Order
                                </button>
                            )}
                            {/* {order.status === "Pending" && (
                                <button
                                    className="track-btn"
                                    onClick={() => {
                                        setModalOpen(true);
                                        setModalOrderId(order.transaction_id);
                                    }}
                                >
                                    Track Order
                                </button>
                            )} */}
                            {/* {order.status === "Dispatch" && (
                                <button
                                    className="track-btn"
                                    onClick={() => {
                                        setModalOpen(true);
                                        setModalOrderId(order.transaction_id);
                                    }}
                                >
                                    Out for Delivery
                                </button>
                            )} */}
                            {/* {order.status === "Delivered" && (
                                <button
                                    className="track-btn"
                                    onClick={() => {
                                        navigate(`/track/${order.transaction_id}`)
                                    }}
                                >
                                    Track Order
                                </button>
                            )} */}
                            <button
                                className="track-btn"
                                onClick={() => {
                                    navigate(`/vendortrack/${order.transaction_id}`)
                                }}
                            >
                                Track Order
                            </button>
                        </div>
                    </div>
                    <div className="ordered-by">
                        <p>Ordered by: <span className="ordered-by-name">{order.user_address.name}</span></p>

                    </div>
                    {/* <div className="ordered-by">
                        <h4>Vendor Details:</h4>
                        <p><strong>Name:</strong> {order.vendor_address.name}</p>
                        <p><strong>Phone:</strong> {order.vendor_address.number}</p>
                        <p><strong>Address:</strong> {order.vendor_address.area}</p>
                    </div> */}
                </div>
            );
        }
        return null;
    };


    const handleCancelOrder = () => {
        console.log(`Order ${modalOrderId} canceled.`);
        setCancelModalOpen(false);
    };

    return (
        <>
            <Header />
            <div className="new-orders">
                <div className="new-order-container">
                    <div className="order-page-heading">
                        <h2>My orders history</h2>
                    </div>
                    <div className="order-dropdown">
                        <select
                            name="order-select"
                            id="order-select"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <option value="all-order">All Orders</option>
                            <option value="Pending">Active</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Delivered">Delivered</option>
                            {/* <option value="returned">Returned</option> */}
                        </select>
                    </div>
                    {
                        order.length > 0 ?
                            <>
                                {order.map(renderOrder)}
                            </> :
                            <div className='order-imagebestview'>
                                <img className='order-imageview' src={orderimg} alt="" />
                            </div>
                    }

                </div>
            </div>

            {/* Dispatch Modal */}
            {isModalOpen && (
                <div className="track-overlay">
                    <div className="track-overlay-container">
                        <p>Are you sure you want to dispatch the item?<br /> Once dispatched, it cannot be canceled.</p>
                        <div className="track-actions">
                            <button className="cancel-btn" onClick={() => setModalOpen(false)}>Cancel</button>
                            <button className="dispatch-btn" onClick={() => handleChange("Dispatch")}>Yes, Dispatch</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Cancel Order Modal */}
            {isCancelModalOpen && (
                <div className="track-overlay">
                    <div className="track-overlay-container">
                        <p>Are you sure you want to cancel the order?<br /> This action cannot be undone.</p>
                        <div className="track-actions">
                            <button className="cancel-btn" onClick={() => setCancelModalOpen(false)}>Cancel</button>
                            <button className="dispatch-btn" onClick={() => handleChange("Cancelled")}>Yes, Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewOrder;
