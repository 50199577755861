import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Global.css'; // Import your CSS
import jsQR from 'jsqr'; // Import the jsQR library
import { useNavigate } from 'react-router-dom';
import { Box, Spinner, Text, useToast } from '@chakra-ui/react';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ScanQR = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // Canvas to analyze the QR code
  const streamRef = useRef(null);
  const login = JSON.parse(localStorage.getItem("login")) || ""
  const [formData, setFormData] = useState({ permanentId: '', amount: '', vendor_permanent_Id: login.permanent_ID });
  const [isScanned, setIsScanned] = useState(false); // State to track if QR has been scanned
  const navigate = useNavigate();
  const [recentData, setRecentData] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [load,setLoad]=useState(true);
  // const toast = useToast();

  useEffect(() => {
    const openCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          // Wait for the video to be ready before starting to scan
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
            scanQR();
          };
        }
      } catch (err) {
        console.error('Error accessing the camera: ', err);
      }
    };

    const scanQR = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const ctx = canvas.getContext('2d');

        // Continuously scan for QR code frames
        const scan = () => {
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, canvas.width, canvas.height);

            if (code) {
              setFormData((prevData) => ({ ...prevData, permanentId: code.data }));
              setIsScanned(true); // Mark as scanned and stop displaying video/canvas
            }
          }

          if (!isScanned) {
            requestAnimationFrame(scan); // Repeat the scan if not scanned
          }
        };

        scan();
      }
    };

    if (!isScanned) {
      openCamera();
    }

    return () => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [isScanned]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log(formData)
  const submitformData = async (e) => {
    e.preventDefault();
    setLoad(false)
    try {
      const response = await fetch(`https://100billionoms.com/vendorSell`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendor_permanent_Id: formData.vendor_permanent_Id,
          user_permanent_Id: formData.permanentId,
          amount: formData.amount
        }),
      })
      const res = await response.json();
      if (response.ok) {
        toast.success('Added Successfully!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/vendor-wallet");
        setLoad(true)
      } else if (response.status === 400) {
        setErrorMessage(res.msg || "Something went wrong.");
        setLoad(true)

      }
      console.log(res);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.msg || "Something went wrong.");
      setLoad(true)


    }
  }

  const fetchRecentData = () => {
    fetch(`https://100billionoms.com/vendorSell/${login.permanent_ID}`)
      .then((res) => res.json())
      .then((data) => {
        // Sort the data by operationDate in descending order
        const sortedData = data.sort((a, b) => new Date(b.operationDate) - new Date(a.operationDate));

        // Use a Map to store only the latest transaction for each user_permanent_Id
        const uniqueUsers = new Map();

        sortedData.forEach((transaction) => {
          if (!uniqueUsers.has(transaction.user_permanent_Id)) {
            uniqueUsers.set(transaction.user_permanent_Id, transaction);
          }
        });

        // Convert the Map values back to an array to get the final filtered result
        const filteredData = Array.from(uniqueUsers.values());
        console.log(filteredData);
        setRecentData(filteredData);
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    fetchRecentData();
  }, [])

  return (
    <>
      <Header />
      <ToastContainer />
      <Box mt={"40px"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
        <Text fontSize={"3xl"} textAlign={"center"} color={"red"}>Oops!</Text>
        <Text fontSize={"2xl"} textAlign={"center"}>Scan QR is not available in this device.</Text>
        <Text fontSize={"xl"} textAlign={"center"}>Try with your mobile device</Text>
      </Box>
      <div className="scan">
        <p className="scan-qr">Scan any QR code</p>

        {/* Conditionally render the scanner only if not scanned */}
        {!isScanned && (
          <div className="scanner-camera">
            <video ref={videoRef} className="camera-feed" autoPlay playsInline />
            <canvas ref={canvasRef} className="qr-canvas" />
          </div>
        )}

        <p className="scan-align">Please align QR within the scanner</p>

        <div className={`manual ${isScanned ? 'full-screen' : ''}`}>
          <form onSubmit={submitformData}>
            <div className="manual-input">
              <input
                type="text"
                name="permanentId"
                placeholder="Enter Permanent ID"
                value={formData.permanentId}
                onChange={handleInputChange}
                onFocus={() => setIsScanned(true)}
                required
              />
            </div>
            <div className="manual-input">
              <input
                type="number"
                name="amount"
                placeholder="Enter Amount"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </div>
            {
              load?<button type="submit" className="submit-btn">
              Submit
            </button>:<button  className="submit-btn">
              <Spinner/>
            </button>
            }
            {isScanned && (
              <button className="close-btn" onClick={() => setIsScanned(false)}>
                ✖
              </button>
            )}

            {errorMessage && (
              <p className="error-message" style={{ color: 'red', marginTop: '10px' }}>
                {errorMessage}
              </p>
            )}
          </form>


        </div>
        <div className="manual-recent">
          <p className="recent">Recent</p>
          <div className="recent-people">
            {
              recentData.map((ele, idx) => (
                <div className="recent-people-list"
                  onClick={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      permanentId: ele.user_permanent_Id,
                    }));
                    setIsScanned(true); // Call this separately
                  }}>
                  <img src="https://picsum.photos/50/50" alt="John Doe" />
                  <p>{ele.user_name}</p>
                </div>
              ))
            }
          </div>
        </div>

      </div>
    </>
  );
};

export default ScanQR;
