import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../CSS/Global.css';
import axios from 'axios';
import { CloseButton, Spinner, useToast } from '@chakra-ui/react';

const Login = () => {
    const navigate = useNavigate(); // Initialize navigate for navigation
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const [changePass, setChangePassword] = useState('');
    const [confirmPass, setConfirmPassword] = useState('');
    const toast = useToast();
    const [load1, setLoad1] = useState(true);
    const [resendCooldown, setResendCooldown] = useState(0);
    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otp, setOtp] = useState('');
    const [resendClicked, setResendClicked] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [load2, setLoad2] = useState(true);
    const [load3, setLoad3] = useState(true);
    const dummy = JSON.parse(localStorage.getItem("reset")) || ""

    const handleForgotPassword = async () => {
        console.log('Password reset email sent to:', forgotEmail);
        localStorage.setItem("reset", JSON.stringify({ email: forgotEmail }));
        setLoad2(false);

        try {
            const response = await fetch(`https://100billionoms.com/register/api/reset-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: forgotEmail })
            })

            const res = await response.json();
            console.log(res)
            if (response.ok) {
                // toast({
                //     description: `Sign-Up first`,
                //     status: 'success',
                //     duration: 5000,
                //     position: 'top-center',
                //     isClosable: true,
                // });
                setIsForgotPasswordModalOpen(false);
                setIsOtpModalOpen(true)
                setLoad2(true);
            }
        } catch (error) {
            console.log(error);
            setLoad2(true);
        }
    };

    const handleGoogleAuthSuccess = async (response) => {
        console.log('Google authentication success:', response);
        try {
            const res = await axios.post("https://100billionoms.com/register/api/google-signin", {
                token: response.credential
            });
            console.log(res.status);
            if (res.data.success) {
                console.log(res.data.user);
                localStorage.setItem("login", JSON.stringify(res.data.user))
                navigate("/");
                window.location.reload();
            } else if (res.data.success === false) {
                navigate("/register")
                toast({
                    description: `Sign-Up first`,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Redirect to the register page with an error message
                navigate("/register");
                toast({
                    description: `Sign-Up first`,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            } else {
                console.error("Google Sign-In Error:", error.response ? error.response.data : error.message);
            }
        }
    };

    const handleGoogleAuthFailure = (error) => {
        console.error('Google authentication error:', error);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Logged in:', { email, password });
        // Handle login logic here
        try {
            const response = await axios.post('https://100billionoms.com/login', {
                email,
                password
            });

            if (response.status === 200) {
                localStorage.setItem("login", JSON.stringify(response.data.userR));
                navigate('/');
                toast({
                    description: `Logged in successfully`,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                window.location.reload();
                //   setload(true);
            }
        } catch (error) {
            console.log(error);
            toast({
                description: `${error.response.data.message}`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const handleOtpChange = (index, value) => {
        let newOtp = otp.split('');
        newOtp[index] = value.replace(/\D/g, '');
        setOtp(newOtp.join(''));

        if (value && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    const handleOtpPaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, 6);
        if (pasteData) {
            setOtp(pasteData);
            pasteData.split('').forEach((value, index) => {
                if (document.getElementById(`otp-${index}`)) {
                    document.getElementById(`otp-${index}`).value = value;
                }
            });
        }
    };

    const handleResendOtp = async () => {
        setResendClicked(true);
        if (resendCooldown > 0) return;
        console.log('Resending OTP to:', email);
        try {
            const response = await axios.post("https://100billionoms.com/register/api/resetresend-otp", { "email": forgotEmail });
            console.log(response)
            if (response.data.success) {
                console.log('OTP sent to:', email);
                // handleShow8();
                setIsOtpModalOpen(true);
                toast({
                    description: `OTP sent to: ${email}`,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                setResendCooldown(180)
                // setLoad(true);
            } else {
                // setLoad(true);
                toast({
                    description: `${response.data.message}`,
                    status: 'warning',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
        } catch (error) {
            // alert(error.response.data.message);
            toast({
                description: `${error.response.data.message}`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            // setLoad(true);
            console.error("Sign-Up Error:", error);
        }

    };

    const handleOtpVerification = async () => {
        // const otp = otpDigits.join('');
        // console.log('OTP verified:', otp);
        setLoad1(false);
        try {
            const response = await axios.post("https://100billionoms.com/register/api/verify-otp", {
                email: dummy.email,
                otp: otp
            })

            if (response.data.success) {
                setOtpVerified(true);
                // setIsOtpModalOpen(false);
                setIsForgotPasswordModalOpen(false);
                setIsOtpModalOpen(false)
                setIsPasswordModalOpen(true)
                toast({
                    description: `OTP verified`,
                    status: 'success',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
                setLoad1(true);
            }
            console.log(response);
        } catch (error) {
            toast({
                description: `Invalid OTP`,
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
            setLoad1(true);
        }

    };

    const handleChnagePassword = async () => {
        setLoad3(false)
        if (changePass === confirmPass) {
            try {
                const response = await axios.post('https://100billionoms.com/register/api/reset-password', {
                    email: forgotEmail,
                    newPassword: changePass
                });

                if (response.data.success) {
                    // localStorage.setItem("login", JSON.stringify(response.data.userR));
                    // navigate('/');
                    // toast({
                    //     description: `Logged in successfully`,
                    //     status: 'success',
                    //     duration: 5000,
                    //     position: 'top-center',
                    //     isClosable: true,
                    // });
                    //   setload(true);
                    setIsPasswordModalOpen(false);
                    setLoad3(true)
                    toast({
                        description: `Password Changed Successfully`,
                        status: 'success',
                        duration: 5000,
                        position: 'top-center',
                        isClosable: true,
                    });

                }

            } catch (error) {
                console.log(error);
                setLoad3(true)
                toast({
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 5000,
                    position: 'top-center',
                    isClosable: true,
                });
            }
        } else {
            setLoad3(true)
            toast({
                description: `Password doesn't natch`,
                status: 'error',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        if (resendCooldown > 0) {
            const timer = setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [resendCooldown]);

    return (
        <>
            {/* <button onClick={()=>setIsPasswordModalOpen(true)}>psb</button> */}
            <div className='form-component'>
                <div className="form-flex">
                    <div className="left-flex">
                        {/* <h3 className='left-column'>Welcome Back to <span>Bigbull Paisewala</span></h3> */}
                    </div>
                    <GoogleOAuthProvider clientId="670956211832-b8rgqcqfq919jql4iatblqk60f4d4iud.apps.googleusercontent.com">
                        <div className="register-container">
                            <h2>Login to Your Account</h2>

                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div className="password-container">
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        id="toggle-password-button"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {isPasswordVisible ? (
                                            <i className="fa-solid fa-eye-slash"></i>
                                        ) : (
                                            <i className="fa-solid fa-eye"></i>
                                        )}
                                    </button>
                                </div>

                                <button type="submit" className='form-button'>Login</button>
                            </form>

                            {/* <p className='divider'>Or</p> */}
                            <div className="auth-options">
                                <GoogleLogin
                                    onSuccess={handleGoogleAuthSuccess}
                                    onError={handleGoogleAuthFailure}
                                />
                            </div>

                            <p className='already'>
                                Don't have an account?
                                <span onClick={() => navigate('/register')} className="login-link"> Register here</span>
                            </p>

                            <p className='forgot-password' onClick={() => setIsForgotPasswordModalOpen(true)}>
                                Forgot your password?
                            </p>

                            <Modal
                                isOpen={isForgotPasswordModalOpen}
                                onRequestClose={() => setIsForgotPasswordModalOpen(false)}
                                className="customotp-modal"
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2>Reset Password</h2>
                                        <span
                                            className="close-buttonforgetpage"
                                            onClick={() => setIsForgotPasswordModalOpen(false)}
                                        >
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                    </div>
                                    <p>Enter your email to receive a OTP.</p>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={forgotEmail}
                                        onChange={(e) => setForgotEmail(e.target.value)}
                                        required
                                    />
                                    {
                                        load2 ? <button
                                            type="button"
                                            className="form-button"
                                            onClick={handleForgotPassword}
                                        >
                                            Send OTP
                                        </button> :
                                            <button
                                                type="button"
                                                className="form-button"
                                            // onClick={handleForgotPassword}
                                            >
                                                <Spinner />
                                            </button>
                                    }
                                </div>
                            </Modal>

                            <Modal
                                isOpen={isPasswordModalOpen}
                                onRequestClose={() => setIsPasswordModalOpen(false)}
                                className="customotp-modal"
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2>Reset Password</h2>
                                        <span
                                            className="close-buttonforgetpage"
                                            onClick={() => setIsPasswordModalOpen(false)}
                                        >
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                    </div>
                                    <p>Enter your Password.</p>
                                    <input
                                        type="text"
                                        placeholder="Password"
                                        value={changePass}
                                        onChange={(e) => setChangePassword(e.target.value)}
                                        required
                                    />
                                    <div className="password-container">
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            value={confirmPass}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        <button
                                            type="button"
                                            id="toggle-password-button"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {isPasswordVisible ? (
                                                <i className="fa-solid fa-eye-slash"></i>
                                            ) : (
                                                <i className="fa-solid fa-eye"></i>
                                            )}
                                        </button>
                                    </div>
                                    {
                                        load3 ?
                                            <button
                                                type="button"
                                                className="form-button"
                                                onClick={handleChnagePassword}
                                            >
                                                Change Password
                                            </button> : <button
                                                type="button"
                                                className="form-button"
                                            // onClick={handleChnagePassword}
                                            >
                                                <Spinner />
                                            </button>
                                    }
                                    {/* :
                                            <button
                                                type="button"
                                                className="form-button"
                                            // onClick={handleForgotPassword}
                                            >
                                                <Spinner />
                                            </button>
                                    } */}
                                </div>
                            </Modal>

                            <Modal isOpen={isOtpModalOpen} onRequestClose={() => setIsOtpModalOpen(false)} className="otp-modal">
                                <h2>Verify OTP</h2>
                                <p>Check your email.</p>
                                <p>We've sent you a code to <b>{forgotEmail}</b></p>

                                <div className="otp-inputs" onPaste={handleOtpPaste}>
                                    {[...Array(6)].map((_, index) => (
                                        <input
                                            key={index}
                                            id={`otp-${index}`}
                                            type="text"
                                            maxLength="1"
                                            value={otp[index] || ''}
                                            // style={{color:"black"}}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                        />
                                    ))}
                                </div>

                                <p className="resend-code">
                                    Didn’t get the code?{' '}
                                    <span
                                        onClick={resendCooldown === 0 ? handleResendOtp : null}
                                        className={resendCooldown === 0 ? "resend-link active" : "resend-link disabled"}
                                    >
                                        {resendCooldown > 0 ? `Resend in ${resendCooldown}s` : 'Click to resend'}
                                    </span>
                                </p>

                                {
                                    load1 ? <button type="button" className='form-button' onClick={handleOtpVerification}>
                                        Verify OTP
                                    </button> : <button type="button" className='form-button' >
                                        <Spinner />
                                    </button>
                                }
                            </Modal>

                        </div>
                    </GoogleOAuthProvider>
                </div>

            </div>
        </>
    );
};

export default Login;
