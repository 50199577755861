import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import grocery from '../Images/001-groceries.png';
import cosmetics from '../Images/002-cosmetics.png';
import fastFood from '../Images/003-fast-food.png';
import vegetables from '../Images/004-vegetable.png';
import hotels from '../Images/005-hotel.png';
import fish from '../Images/006-fish.png';
import egg from '../Images/007-chicken.png';
import meat from '../Images/008-meat.png';
import fruits from '../Images/009-fruits.png';
import dairy from '../Images/010-milk.png';
import juice from '../Images/011-drink.png';
import medical from '../Images/012-syringe.png';
import rickshaw from '../Images/013-rickshaw.png';
import tour from '../Images/014-travel-and-tourism.png';
import garment from '../Images/015-clothing.png';
import '../CSS/Global.css';
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Header from './Header';
import WalletCard from './VendorWalletCard';
import TimeCountDown from './Coundown';
import user from '../Images/user.jpg';
import CryptoJS from 'crypto-js'; // For decryption
import axios from 'axios';

const VendorWallet = () => {
    const [showFilter, setShowFilter] = useState(false);
    const [visibleStatements, setVisibleStatements] = useState(20); // Start with 20 visible
    const navigate = useNavigate();
    const [showFilter1, setShowFilter1] = useState(false);
    const [visibleStatements1, setVisibleStatements1] = useState(20); // Start with 20 visible
    const statementRef = useRef(null); // Create  a ref for the statement div
    const statementRef1 = useRef(null);
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [userwallet, setUserWallet] = useState({})
    const [userwalletP, setUserWalletP] = useState({})
    const [state, setState] = useState([]);
    const [stateP, setStateP] = useState([]);
    const [filteredState, setFilteredState] = useState([]);
    const [filteredStateP, setFilteredStateP] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({ startDate: '', endDate: '', amount: '' });
    const [filterCriteriaP, setFilterCriteriaP] = useState({ startDate: '', endDate: '', amount: '' });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [checkstore, setCheckStore] = useState([])
    const [load, setLoad] = useState(true)
    const [showCountdown, setShowCountdown] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [tier, setTier] = useState("Basic");
    const [enddate, setEndDate] = useState()
    const [isPaid, setIsPaid] = useState(false);
    const [isPlus, setIsPlus] = useState(true);
    const [isTabOpen, setIsTabOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const toast = useToast();
    const location = useLocation();
    const [walletprice, setWalletPrice] = useState()
    const toggleTab = () => {
        setIsTabOpen(!isTabOpen);
        setIsPlus(!isPlus); // Toggle plus/cross state
    };
    // Example statements data


    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };
    const toggleFilter1 = () => {
        setShowFilter1(!showFilter1);
    };



    const handleStatementClick = () => {
        if (statementRef.current) {
            statementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };



    // Navigation handlers for buttons
    const handleScan = () => {
        navigate('/scanqr');
    };


    const fetchWalletUser = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/store/getsinglestore/getwallet/${login.permanent_ID}`);
            const res = await response.json();
            console.log(res);
            setUserWallet(res);
        } catch (error) {
            console.log(error)
        }
    }

    console.log(userwallet)

    const fetchData = () => {
        fetch(`https://100billionoms.com/vendorSell/current/${login.permanent_ID}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((ans) => {
                setState(ans.reverse())
            })
            .catch((err) => console.log(err));
    };

    const fetchDataPurchase = () => {
        fetch(`https://100billionoms.com/userBuy/current/${login.permanent_ID}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans)
                setStateP(ans.reverse())
            })
            .catch((err) => console.log(err));
    };

    const fetchDataVendor = () => {

        fetch(`https://100billionoms.com/vendorSell/${login.permanent_ID}`, {

        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans)
                setFilteredState(ans.reverse());
            })
            .catch((err) => console.log(err));
    };

    const fetchDataVendorPurchase = () => {

        fetch(`https://100billionoms.com/userBuy/${login.permanent_ID}`, {

        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans);
                setFilteredStateP(ans.vendors.reverse());
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        fetchDataVendor();
        fetchDataPurchase();
        fetchDataVendorPurchase();
    }, []);


    const calculateTotal = (data) => data.reduce((acc, curr) => {
        let currAmount = typeof curr.amount === 'string'
            ? parseFloat(curr.amount.replace(/[^0-9.-]+/g, ''))
            : curr.amount;
        return acc + (currAmount || 0);
    }, 0);

    const calculateTotal1 = (data) => data.reduce((acc, curr) => {
        let currAmount = typeof curr.amount === 'string'
            ? parseFloat(curr.amount.replace(/[^0-9.-]+/g, ''))
            : curr.amount;
        return acc + (currAmount || 0);
    }, 0);


    const calculateTotalCommission = (data) => data.reduce((acc, curr) => {
        let currCommission = typeof curr.commission === 'string'
            ? parseFloat(curr.commission.replace(/[^0-9.-]+/g, ''))
            : curr.commission;
        return acc + (currCommission || 0);
    }, 0);

    // Calculate `totalAmount` based on the presence of filters
    const totalAmount = (
        filterCriteria.startDate || filterCriteria.endDate || filterCriteria.amount
    ) ? calculateTotal(filteredState) : calculateTotal(state);

    const totalAmount1 = (
        filterCriteria.startDate || filterCriteria.endDate || filterCriteria.amount
    ) ? calculateTotal1(filteredStateP) : calculateTotal1(stateP);


    const totalCommission = (
        filterCriteria.startDate || filterCriteria.endDate
    ) ? calculateTotalCommission(filteredState) : calculateTotalCommission(state);

    const applyFilter = () => {
        const { startDate, endDate, amount } = filterCriteria;
        const query = new URLSearchParams({ startDate, endDate, amount }).toString();

        fetch(`https://100billionoms.com/vendorSell/${login.permanent_ID}?${query}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans)
                setFilteredState(ans);
            })
            .catch((err) => console.log(err));
    };


    const applyFilterP = () => {
        const { startDate, endDate, amount } = filterCriteriaP;
        const query = new URLSearchParams({ startDate, endDate, amount }).toString();

        fetch(`https://100billionoms.com/userBuy/${login.permanent_ID}?${query}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((ans) => {
                console.log(ans)
                setFilteredStateP(ans.vendors);
            })
            .catch((err) => console.log(err));
    };


    // Handle changes to filter input fields
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterCriteria(prev => ({ ...prev, [name]: value }));
    };

    const handleFilterChangeP = (e) => {
        const { name, value } = e.target;
        setFilterCriteriaP(prev => ({ ...prev, [name]: value }));
    };

    const clearFilter = () => {
        setFilterCriteria({ startDate: '', endDate: '', amount: '' })
        fetchDataVendor();
    }

    const clearFilterP = () => {
        setFilterCriteriaP({ startDate: '', endDate: '', amount: '' })
        fetchDataVendorPurchase();
    }

    // View more statements (show 20 more each time)
    const handleViewMore = () => setVisibleStatements(prev => prev + 20);
    const handleViewMore1 = () => setVisibleStatements1(prev => prev + 20);
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        calculateTotal(state);
        calculateTotalCommission(state);
    }, [state]);
    useEffect(() => {
        calculateTotal1(stateP);
    }, [stateP]);


    console.log(totalCommission)
    const handleShowQR = () => {
        navigate('/showqr');
    };

    const decrypt = (encryptedData, iv) => {
        const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

        // Convert key, IV, and encrypted data to appropriate CryptoJS formats
        const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
        const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

        // Decrypt the data using AES with CBC mode
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Return the decrypted UTF-8 string
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    const fetchWalletUserSingly = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/wallet-reg-fee/users/${login.permanent_ID}`);
            const res = await response.json();
            const decryptedData = decrypt(res.data.data, res.data.iv);
            if (decryptedData === "[]") {
                onOpen(); // Open modal if decrypted data is empty
            } else {
                const walletData = JSON.parse(decryptedData);
                checkValidity(walletData);
                console.log(decryptedData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkValidity = (walletData) => {
        const currentDate = new Date();
        let validSubscription = false;

        for (const data of walletData) {
            const startDate = new Date(data.startDate);
            const endDate = new Date(data.endDate);

            // Check if the current date is within the subscription period and status is not "Failed"
            if (currentDate >= startDate && currentDate <= endDate && data.status !== "Failed") {
                validSubscription = true;
                setEndDate(endDate.toISOString());
                const daysRemaining = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                setTimeRemaining(daysRemaining);

                // Assign tiers based on remaining days
                if (daysRemaining > 21) {
                    setTier("Premium");
                } else if (daysRemaining > 7) {
                    setTier("Standard");
                } else {
                    setTier("Basic");
                    setShowCountdown(true);
                }

                break;
            }
        }

        if (!validSubscription) {
            onOpen(); // Open modal if no valid subscription
        }
    };


    const fetchWalletData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/wallet-registration`);
            const res = await response.json();
            setWalletPrice(res.data[0].walletsale_price);
            // console.log(res.data[0].walletsale_price);
        } catch (error) {
            console.log(error);
        }
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };
    const handlePayment = async () => {
        setLoad(false);
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let paymentDetails = {
                user_id: login.permanent_ID,
                user_phone: login.name,
                transaction_id: orderId,
                amount: walletprice * 100,
                validity: 28,
                currency: "INR",
                payment_capture: 1,
                payment_mode: "online",
            };

            // Create order options
            let options = {
                key: "rzp_live_Xe3FsoiFgJrvDM",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "BigBull Paisewala",
                description: "Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'Paid';
                        let result = await axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            //   checkPaymentStatus();
                            // handleSubmit(result.data.payments)
                            navigate("/vendor-wallet")
                            setLoad(true);
                            fetchWalletUserSingly();
                            window.location.reload();
                            // setLoad(false);
                            // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            setLoad(true);
                            // setLoad(false);
                            //   window.location.href = "/error-page"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'Failed';
                        const payment = "Failed"
                        await axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails);
                        // handleSubmit(payment)
                        navigate("/vendor-wallet")
                        setLoad(true);
                        // window.location.href = "/error-page"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "bigbullbusinesssolutionspvtltd@gmail.com",
                    contact: "7757855388"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#1f5215"
                },
                modal: {
                    ondismiss: function () {
                        setLoad(true);
                    },
                },
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                paymentDetails.status = 'Failed';
                const payment = "Failed"
                axios.post(`https://100billionoms.com/wallet-reg-fee`, paymentDetails)
                    .then(() => {
                        navigate("/vendor-wallet") // Redirect to an error page

                        setLoad(true);

                    }).catch(error => {
                        navigate("/vendor-wallet") // Redirect to an error page
                        // setShowModal(false); // Close the modal on success
                        // setLoad(false)
                        setLoad(true);
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserPayment = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/wallet-reg-fee/users/${login.permanent_ID}`)
            const res = await response.json();
            console.log(res);
            const decryptedData = decrypt(res.data.data, res.data.iv);

            // Ensure that the decrypted data is valid before parsing
            if (decryptedData) {
                const parsedData = JSON.parse(decryptedData);
                // setUserData(parsedData);
                console.log(parsedData)
                // Check if any object's status is 'Paid'


                // Open modal if not paid

            } else {
                console.error("Decrypted data is empty or invalid");
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlepayfirst = () => {
        if (login) {
            toast({
                description: "Pay Registeration Fee First",
                status: 'warning',
                duration: 5000,
                position: 'top-center',
                isClosable: true,
            });
        }
        toast({
            description: "Login First",
            status: 'warning',
            duration: 5000,
            position: 'top-center',
            isClosable: true,
        });
    }

    console.log(checkstore)
    useEffect(() => {
        fetchWalletUser();
        fetchWalletUserSingly();
        fetchWalletData();
        fetchUserPayment();
    }, [])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Text textAlign={"center"} mt={"30px"}>To Activate Your Wallet you need to pay ₹{walletprice}</Text>
                        <Box mt={"10px"}>
                            <Flex textAlign={"center"} justifyContent={"center"}>

                                {
                                    load ? <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }} onClick={handlePayment} >Pay ₹{walletprice}</Button> :
                                        <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }} ><Spinner /></Button>
                                }
                            </Flex>
                        </Box>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>

                </ModalContent>
            </Modal>
            {console.log(timeRemaining)}
            {/* <Header /> */}
            <div className="wallet-countdown">
                <div className="wallet-countdown-list1">
                    <p className='blinking-text'>{timeRemaining} days left now!</p>
                </div>
                <div className="wallet-countdown-list">
                    <TimeCountDown timeRemaining={timeRemaining} enddate={enddate} />
                </div>
                {timeRemaining <= 7 &&
                    < div className="wallet-countdown-list" onClick={handlePayment}>
                        <button className='wallet-pay-now'>Renew</button>
                    </div>
                }
            </div >
            <div className="wallet-card">
                <div className="wallet-card-header">
                    <div className="wallet-card-profile">
                        <div className="img">
                            <img src={user} alt="" />
                        </div>
                        <div className="wallet-card-text">
                            <p className='wallet-name'>{userwallet.name}</p>
                            <p className='wallet-permanent-id'>{userwallet.permanent_ID}</p>
                        </div>
                    </div>
                    <div className="wallet-scan-btn">
                        <Link to='/Scanqr'><button className="scan-btn"><i class="fa-solid fa-expand"></i></button></Link>
                    </div>
                </div>
                <div className="wallet-amount-card">
                    <div className="wallet-total-amt">
                        <p>This month sale</p>
                        <h3>₹ {totalAmount}</h3>
                    </div>
                    <div className="wallet-features">
                        <div className="wallet-list">
                            <h3>₹ {totalCommission.toFixed(2)}</h3>
                            <p>Commision</p>
                        </div>
                        <div className='vertical-line'></div>
                        <div className="wallet-list">
                            <h3>₹ {totalAmount1}</h3>
                            <p>Purchase</p>
                        </div>
                        <div className='vertical-line'></div>
                        <div className="wallet-list">
                            <Link to='/showqr'>
                                <h3 className='qr-code'><i class="fa-solid fa-qrcode"></i></h3>
                                <p>show QR</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='wallet-statement-category vendor-statement-category'>
                <div className='wallet-statement' ref={statementRef}> {/* Reference to this div */}
                    <div className='wallet-statement-title'>
                        <h2>Sales Statement</h2>
                        <p onClick={toggleFilter} className='filter-icon'>
                            <i className="fa-solid fa-filter"></i>
                        </p>
                    </div>

                    {showFilter && (
                        <div className='filter-form'>
                            <div className='filter-form-flex'>
                                <div className='filter-date'>
                                    <label>Start Date:</label>
                                    <input type='date' name='startDate' value={filterCriteria.startDate} onChange={handleFilterChange} />
                                </div>
                                <div className='filter-date'>
                                    <label>End Date:</label>
                                    <input type='date' name='endDate' value={filterCriteria.endDate} onChange={handleFilterChange} />
                                </div>
                                <div className='filter-amount'>
                                    <label>Amount:</label>
                                    <input type='number' name='amount' value={filterCriteria.amount} placeholder='Enter amount' onChange={handleFilterChange} />
                                </div>
                            </div>
                            <div className='filterapplydivchange'>
                                <button className='filter-button' onClick={applyFilter}>Apply Filter</button>
                                <button className='filterclear' onClick={clearFilter}>Clear Filter</button>
                            </div>
                        </div>
                    )}

                    {
                        filteredState.length > 0 ?
                            <div className='wallet-statement-table'>
                                {filteredState.slice(0, visibleStatements).map((statement, index) => (
                                    <div key={index} className='date-amount'>
                                        <div className='wallet-date'>
                                            <p>{new Date(statement.operationDate).toISOString().split('T')[0]}</p>
                                        </div>
                                        <div className='wallet-amount'>
                                            <p className='amount'>{statement.amount}</p>
                                            <p className='amountcommissionstate'>- {(statement.commission)}</p>
                                            <p className='vendor-name'>{statement.user_name}-{statement.user_permanent_Id}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> : <Text mt={"10px"} textAlign={"center"}>No history till now</Text>
                    }
                    {visibleStatements < filteredState.length && (
                        <div className='view-more'>
                            <button className='view-more-btn' onClick={handleViewMore}>
                                View More
                            </button>
                        </div>
                    )}

                </div>

                <div className='wallet-statement' ref={statementRef1}> {/* Reference to this div */}
                    <div className='wallet-statement-title'>
                        <h2>Purchase Statement</h2>
                        <p onClick={toggleFilter1} className='filter-icon'>
                            <i className="fa-solid fa-filter"></i>
                        </p>
                    </div>

                    {showFilter1 && (
                        <div className='filter-form'>
                            <div className='filter-form-flex'>
                                <div className='filter-date'>
                                    <label>Start Date:</label>
                                    <input type='date' name='startDate' value={filterCriteriaP.startDate} onChange={handleFilterChangeP} />
                                </div>
                                <div className='filter-date'>
                                    <label>End Date:</label>
                                    <input type='date' name='endDate' value={filterCriteriaP.endDate} onChange={handleFilterChangeP} />
                                </div>
                                <div className='filter-amount'>
                                    <label>Amount:</label>
                                    <input type='number' name='amount' value={filterCriteriaP.amount} placeholder='Enter amount' onChange={handleFilterChangeP} />
                                </div>
                            </div>
                            <div className='filterapplydivchange'>
                                <button className='filter-button' onClick={applyFilterP}>Apply Filter</button>
                                <button className='filterclear' onClick={clearFilterP}>Clear Filter</button>
                            </div>
                        </div>
                    )}

                    {
                        filteredStateP.length > 0 ? <div className='wallet-statement-table'>
                            {filteredStateP.slice(0, visibleStatements1).map((statement, index) => (
                                <div key={index} className='date-amount'>
                                    <div className='wallet-date'>
                                        <p>{new Date(statement.operationDate).toISOString().split('T')[0]}</p>
                                    </div>
                                    <div className='wallet-amount'>
                                        <p className='amount'>{statement.amount}</p>
                                        <p className='vendor-name'>{statement.vendor_name} - {statement.vendor_permanent_Id}</p>
                                    </div>
                                </div>
                            ))}
                        </div> : <Text mt={"10px"} textAlign={"center"}>No history till now</Text>
                    }

                    {visibleStatements1 < filteredStateP.length && (
                        <div className='view-more'>
                            <button className='view-more-btn' onClick={handleViewMore1}>
                                View More
                            </button>
                        </div>
                    )}
                </div>

            </div>
            <nav className="header__column1 header__nav">
                <ul>
                    <li>
                        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                            <i className="fas fa-home"></i> <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        {
                            isPaid ? <Link to="/world" className={location.pathname === '/world' ? 'active' : ''}>
                                <i className="fas fa-globe"></i> <span>Big World</span>
                            </Link> : <Link aria-disabled onClick={handlepayfirst} className={location.pathname === '/world' ? 'active' : ''}>
                                <i className="fas fa-globe"></i> <span>Big World</span>
                            </Link>
                        }

                    </li>
                    {
                        login.type === "vendor" ?
                            <li>
                                <div className="header-plus-icon" onClick={toggleTab}>
                                    <i className={`fa-solid ${isPlus ? 'fa-plus' : 'fa-xmark'}`}></i> {/* Conditional icon rendering */}
                                </div>
                            </li> : null
                    }

                    <li>
                        {
                            login.type === "vendor" ? <Link to="/vendor-wallet" className={location.pathname === '/wallet' ? 'active' : ''}>
                                <i className="fas fa-wallet"></i> <span>Wallet</span>
                            </Link> : <Link to="/wallet" className={location.pathname === '/wallet' ? 'active' : ''}>
                                <i className="fas fa-wallet"></i> <span>Wallet</span>
                            </Link>
                        }
                    </li>
                    <li className='header-order'>
                        <Link to="/order" className={location.pathname === '/order' ? 'active' : ''}>
                            <i class="fa-solid fa-bag-shopping"></i> <span>Orders</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default VendorWallet;
