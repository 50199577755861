import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import orderimg from "../Images/orderspage.webp"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function OrderSuccess() {
    const { id } = useParams();
    const navigate=useNavigate();
    const [data,setData]=useState({})

    const fetchData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/cart-orders/order/${id}`)
            const res = await response.json();
            setData(res.data)
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
            <Box>
                <Box margin={"auto"} mt={{base:"0px",sm:"0px",md:"0px",lg:"20px",xl:"20px"}} p={"20px"} borderRadius={"10px"} boxShadow={{base:"none",sm:"none",md:"none",lg:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",xl:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"}}  w={{base:"100%",sm:"100%",md:"100%",lg:"60%",xl:"60%"}}>
                    <Box>
                        <Image margin={"auto"} borderRadius={"10px"} display={"block"} w={{base:"70%",sm:"70%",md:"70%",lg:"50%",xl:"50%"}}  src={orderimg} />
                        <Text mt={"20px"} textAlign={"center"} fontSize={{base:"xl",sm:"xl",md:"xl",lg:"3xl",xl:"3xl"}} fontWeight={"600"}>
                            Order Placed Successfully
                        </Text>
                        <Box textAlign={"center"} w={{base:"90%",sm:"90%",md:"90%",lg:"50%",xl:"50%"}} margin={"auto"} mt={"20px"}>
                            <Flex alignItems={"center"} margin={"auto"}>
                                <Button display={"block"} _hover={{ bg: "#F91919", color: "white" }} margin={"auto"} textAlign={"center"} bg={"#F91919"} color={"white"} onClick={()=>navigate(`/track/${data.transaction_id}`)}>Track Order</Button>
                                <Button display={"block"} _hover={{ bg: "#F91919", color: "white" }} margin={"auto"} textAlign={"center"} bg={"#F91919"} color={"white"} onClick={()=>navigate(`/order`)}>See all Orders</Button>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default OrderSuccess;